export const environment = {
  production: false,
  hmr: false,
  // Local URL
  // apiUrl: 'http://43.205.48.160:3044/',
  // baseApiUrl: 'http://43.205.48.160:3044/',

  // Dev URL
  apiUrl: 'https://devapi.splitlevel.co/',
  baseApiUrl: 'https://devapi.splitlevel.co/',
  key_as: 'NTIwQDUyMUA1MjJANTIzQDUyNEA1MjVANTI2QDUyN0A1MjhANTI5QDUzMEA1MzFANTMyQDUzM0A1MzRANTM1QDUzNkA1MzdANTM4QDUzOUA1NDBANTQxQDU0MkA1NDNANTQ0QDU0NUA1NDZANTQ3QDU0OEA1NDlANTUwQDU1MQ==',
  key_vec: 'NTFANTJANTNANTRANTVANTZANTdANThANTlANjBANjFANjJANjNANjRANjVANjY=',
};
