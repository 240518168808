export const locale = {
  lang: 'es',
  data: {
    commonSlags: {
      withdraw: "Retirar",
      withdrawCapital: "Retirar capital",
      cntue: "Continue",
      yes: "Sí",
      no: "No",
      cancel: "cancelar",
      addAcc: "Agregar dirección",
      address: "Ponle un nombre a tu billetera",
      urWlt: "Tu billetera",
      noWlt: "No se encontró billetera",
      cancelcAP: "CANCELAR",
      saveAdd: "GUARDAR DIRECCIÓN",
      bitWithdrawalAd: "Direcciones de retiro de Bitcoin",
      giveWalletName: "Dale un nombre a tu billetera",
      wallet: "billetera",
      desReq: "Se requiere descripción",
      addReq: "Las dirección es obligatoria",
      amtReq: "Es necesario La cantidad ",
      amtExceed: "La cantidad excede su saldo",
      bitAdd: "Su dirección de retiro de Bitcoin",
      password: 'Contraseña',
      srch: 'Buscar',
      wthdrw: 'Retiro',
      bckLgn: 'Volver al inicio de sesión',
      valExtn: 'Seleccione una extensión válida',
      minwith: 'es la cantidad mínima a retirar',
      enbl2fa: 'Habilite 2FA y vuelva a intentarlo',
      amtGrt: 'La cantidad debe ser mayor que',
      amtexced: 'Cantidad que excede su saldo',
      unltrnsdtls: 'El retiro está en proceso, vuelva a verificar más tarde.'
    },

    forgotpassword: {
      title: "Olvidé la contraseña en su",
      rstPwdTtl: "Restablecer contraseña",
      reqOtp: "Se requiere un código de verificación de correo electrónico",
      emvfCode: "Código de verificación de correo electrónico",
      sndcd: "Obtener código",
      rsnd: "Reenviar",
      rst: "Restablecer",
      ent6RcvBy: "Ingrese los 6 dígitos recibidos por",
      entrEml: 'Ingrese su correo electrónico y le enviaremos instrucciones para restablecer su contraseña',
      eml: 'Correo electrónico',
      emlReq: 'Se requiere correo electrónico'
    },
    G2FA: {
      verifyUr2Fa: "Verifique su 2FA",
      verifyBtn: "Verificar",
      enter6dgt: "Ingrese el código de 6 dígitos",
      enterValid: "Ingrese un código válido",
      plzVrfy: "Introduzca un código 2FA válido",
      entr2fa: "Ingrese su código 2FA",
      accessapp: "Accede a tu aplicación 2FA y luego ingresa el código de 6 dígitos que te aparece para",
      enterOTP: "Introduzca el código de verificación de correo electrónico",
      valid2FA: "Ingrese un código 2FA válido",
      vrfyitsU: 'Para proteger su cuenta, complete la siguiente verificación',
      sgnNewdev: 'Confirmar inicio de sesión de nuevo dispositivo',
      antphsngCode: 'Código anti-phishing',
      chnAnticode: 'Cambiar código anti-phishing',
      chng: 'Cambiar',
      plzFillPhsnCode: 'El código anti-phishing debe tener entre 4 y 20 caracteres.',
      whtAntiphs: "¿Qué es un código anti-phishing?",
      antiDsc: "Un código anti-phishing es un código que le ayuda a prevenir intentos de phishing desde sitios web o correos electrónicos falsos.",
      hwAnitworks: "¿Cómo funciona?",
      urCrntCod: "Su código antiphishing actual es",
      oncStpAnti: "Una vez que haya configurado su código anti-phishing exclusivo, se incluirá en todos los correos electrónicos genuinos que le enviemos",
    },
    COMMONERRORS: {
      GETBALANCEERROR: "No se puede recuperar el equilibrio en este momento. Vuelve a intentarlo más tarde",
      SESSIONOUT: 'Su sesión de inicio de sesión ha caducado, inicie sesión de nuevo.',
      FIELDISREUIRED: 'Este campo es obligatorio',
      MUSTMATCHPASS: 'Confirme que la contraseña debe coincidir con la contraseña',
      length8: "¡Debe tener al menos 8 caracteres!",
      capMust: "¡Debe contener al menos 1 en mayúsculas!",
      smallMust: "¡Debe contener al menos 1 letra en minúscula!",
      numberMust: "¡Debe contener al menos 1 número!",
      specialMust: "¡Debe contener al menos 1 carácter especial!",
      unableSubmit: "No se puede enviar la solicitud de retiro en este momento. Vuelva a intentarlo más tarde",
      somethingError: "Algo salió mal, inténtalo de nuevo más tarde",
      passwordConf: "Confirmar contraseña",
      passwordConfreq: "Se requiere confirmación de contraseña",
      amountREq: 'Cantidad requerida',
      cptchreq: 'se requiere captcha',
      withReqErr: 'No se puede solicitar el retiro en este momento. Vuelve a intentarlo más tarde.',
    },
    PROFILE: {
      FULLNAME: 'Nombre completo',
      DOBFULL: 'Fecha de nacimiento',
      PHONE: 'Teléfono',
      ADDRESS: 'Dirección',
      POSTZIP: 'Postal / Código postal',
      CITYTOWN: 'Ciudad o pueblo',
      COUNTRYLOC: 'País o ubicación',
      DOCUMENTNO: 'Documento de identificación #',
      TWOFAAUTH: 'Autenticación de dos factores',
      ENTERDOCNUM: 'Ingrese el número o código de su documento nacional de identidad (DNI, cédula de ciudadano, pasaporte, etc). NO escriba cosas como "pasaporte" o "ID" en este campo, solo el número de su documento',
      CUMPLES: 'SPLITLEVEL cumple con lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y la libre circulación de estos datos y por el que se deroga la Directiva 95/46 / CE, Reglamento General de Protección de Datos. ',
      AUTHIMPROVE: 'Mejora la seguridad de la cuenta y la transacción al agregar un segundo método de autenticación más allá de la contraseña de inicio de sesión',
      AUTHSCAN: 'Escanee el código QR que se muestra a continuación, en su aplicación de autenticación favorita (Authy, Google Authenticator o similar). Este es un código único creado para usted, no lo comparta con nadie más. Guárdelo bien, porque es la única vez que se mostrará. Cuando haya configurado correctamente su aplicación con este código, haga clic en Confirmar y el segundo factor de autenticación se habilitará en su cuenta ',
      WARNINGAUTH: '¡Advertencia! Al hacer clic en el botón Confirmar, se activará la autenticación de dos factores para su cuenta. Asegúrese de haber escaneado o copiado correctamente el código antes de continuar; de lo contrario, podría experimentar errores al iniciar sesión. ',
      ENTER2FA: "Ingrese el código de dos factores",
      NME: "Nome",
      SRNME: "Sobrenome",
      DNI: "DNI",
      DNIDOC: "Documento DNI",
      NIE: "NIE ou passaporte",
      NIEDOC: "NIE o documento de pasaporte",
      RMADDRSS: "Endereço do quarto",
      TEL: "Telefone",
      EML: "Email",
      Usrnme: "Nome de usuário",
      pwd: "Senha",
      slfWthId: "Selfie con documento de identificación",
      slfVald: "Una selfie tuya sosteniendo un documento de identidad",
      loginArea: "Área de inicio de sesión",
      loginPlatform: " Plataforma de inicio de sesión",
      loginDevice: "Dispositivo de inicio de sesión ",
      loginTime: "Hora",
      noRecords: "Sin registro",

    },
    MENU: {
      MYACC: {
        COLLAPSIBLE: 'Mi cuenta',
        PROFILE: 'Perfil',
        SECURITY: 'Seguridad',
        EMAILCONTACT: 'Correo electrónico y contacto',
      },
      BALC: {
        COLLAPSIBLE: 'Saldo',
        ACTIVITY: 'Actividad',
        WITHDRAWAL: 'Retiro',
        AVLERN: 'Ingresos disponibles',
        SVNG: 'Ahorros',
      },
      CAPITAL: {
        COLAPSIBLE: 'Capital',
        DEPOSIT: 'Depósito',
        MYCAP: 'Mi capital',
      },
      BONUS: {
        COLLAPSIBLE: 'Bonus',
        SUMMARY: 'Resumen',
        PROFTBONUS: 'Bono de rentabilidad',
        PROFTEXBONUS: 'Bono extra de rentabilidad',
        AGENTBONUS: 'Bonificación de agente',
        ZONEAGENTBONUS: 'Bonificación de agente de zona',
      },
      NEWS: 'Noticias',
      TEAM: 'Equipo',
      INTERNALMSG: 'Mensajes internos',
      NOTIFICATION: 'Notificaciones',
      SUPPORT: 'soporte',
    },
    ACTIVITY: {
      ENTERINFO: 'Ingrese la cantidad en Euros que desea reinvertir de las Ganancias de Capital al Capital Activo.',
      AMTINEUR: 'Importe en Euros (€) *',
      ADDBALTOCAP: 'Agregar saldo al capital',
      MYAVLBAL: 'Mi saldo disponible',
      MYAVLERN: 'Mi ganancia disponible',
      MYBALACT: 'Mi actividad de saldo',
      note: 'Nota: Las usuarios VIP pueden reinvertir solo dos veces en un mes..',
      Ivest: 'Invertir',
      loadMo: 'Carga más',
      moreRecord: 'Más registros',
      noReco: 'No hay registros',
      reInvst: 'Reinvertir',
      sureToIvst: 'Estas segura de invertir'
    },
    WITHDRAWAL: {
      CASHOUTHSTRY: 'Historial de solicitudes de retiro',
      REQCASHOUT: 'Solicitud de retiro',
      withinf: 'Por favor indique la dirección de su billetera Bitcoin y la cantidad en Euros (€) que desea retirar. Una vez realizada tu solicitud antes de las 15h00 (Zona Horaria de Londres), recibirás el BTC correspondiente en tu billetera hasta 48h en días hábiles y 72h los viernes, con el cambio de Bitcoin al momento de recibirlo.',
      WITHINFO: 'Puede retirar dinero de su saldo de SPLITLEVEL en una billetera Bitcoin personal de su elección. Indique a continuación la dirección de su Billetera Bitcoin personal y la cantidad en Euros (€) que desea retirar. Esa cantidad se restará de su saldo actual. Una vez concedida su solicitud, en breve recibirá el BTC correspondiente en su billetera ',
      ttlBtkamt: "Importe total en euros",
      indctAdd: "Indique la dirección de la billetera a la que desea enviar",
      canMin: "Puede retirar saldo de",
      upMax: "hasta un máximo de",
      whatblc: "¿Qué saldo desea retirar?",
      amt3: "3% Importe",
      willDtc: "se deducirá y obtendrá",
      whnBtk: "Cuando retira EURO de su saldo, se debe indicar una billetera a la que desea el equivalente en Bitcoin.",
      rmBck: "Recuerde que las operaciones de Blockchain no se pueden modificar una vez lanzadas, por lo que debe tener mucho cuidado al seleccionar el monto a transferir y a qué dirección de billetera desea recibirlo.",
      selectAddress: "Seleccionar dirección",
      addAddressCaps: "AÑADIR DIRECCIÓN",
      manage: "ADMINISTRAR",
      balWithdraw: "¿Qué saldo desea retirar?",
      enter6DigitBy: "Ingrese el código de 6 dígitos recibido por",
      enter6DigitAuth: "Ingrese el código de 6 dígitos de 2FA",
      sureWantdelAddrs: "¿Está seguro de que desea eliminar esta dirección?",
      adrsPrsnt: "La dirección ya está presente en su lista de direcciones",
      plzVerifyAdrs1st: "Primero verifique su dirección",
      unableToGetWithHistory: 'No se puede obtener el historial de retiros, intente nuevamente más tarde.',
      wdhtDsbld: 'El retiro está deshabilitado en este momento',
      cncWthd: 'Cancelar retiro',
      rSureWthdCan: '¿Está seguro de que desea cancelar el retiro?',
      withdsc: 'Descripción',
      withdsctxt: 'Agregue una descripción sobre este retiro'
    },
    DEPOSIT: {
      depoDeposite: 'Depósito',
      depoNow: 'Depositar ahora',
      depoHistor: 'Historial de depósitos',
      depoShow: 'Mostrar',
      DEPOSITTERM: 'Puede hacer un depósito en su capital SPLITLEVEL enviando Bitcoins (XBT) que se convertirán a EUR y se agregarán a su capital cuando se reciban. Primero, especifique la cantidad que desea depositar; a continuación, se le mostrarán instrucciones sobre cómo completar el depósito en Bitcoins; por favor léalos y sígalos atentamente. ',
      depoEntry: 'entradas',
      depoSearch: 'Buscar',
      depoChoos: 'Elija un método de pago',
      depoHour: 'horas',
      depoNote: 'Nota',
      depoYoucan: 'Puede realizar un depósito en su capital SPLITLEVEL enviando Bitcoins (XBT) que se convertirán a EUR y se agregarán a su capital cuando se reciban. Primero, especifique la cantidad que desea depositar; a continuación, se le mostrarán instrucciones sobre cómo completar el depósito en Bitcoins; por favor léalos y sígalos atentamente. ',
      depoSelect: 'Seleccione una de las opciones siguientes o ingrese el valor manualmente',
      depoSend: 'Enviarás',
      depoGet: 'Obtendrás',
      depoConti: 'Continuar',
      depoExchange: 'El tipo de cambio se fijará durante 30 minutos',
      depTxn: 'Transacción',
      depTimeLeft: 'Tiempo restante para enviar',
      depGet: 'Obtener',
      depSend: 'Enviar',
      depExact: 'Importe exacto',
      depAmnt: 'monto',
      depSendExact: 'Enviar el exacto',
      depSendWithin: 'Enviar dentro del tiempo limitado',
      depToAdrs: 'a esta dirección',
      depBlockChain: 'Blockchain Fee should be',
      depWithdrawalCh: 'El retiro cobra una tarifa de red',
      depCompleteAfter: 'Completo después de 3 confirmaciones',
      depTxnHereDetails: 'Aquí están los detalles e instrucciones sobre cómo completar el pago para depositar la cantidad solicitada en su saldo. Siga estas indicaciones con atención. Una vez que se haya confirmado el pago, la cantidad se acreditará al saldo de su cuenta. ',
      depoTo: 'Para',
      depoIssue: 'Problema al obtener la dirección',
      depoMaxDepo: 'Límite máximo de depósito alcanzado',
      depoYouOnly: 'solo puede depositar por',
      depoMin: 'Mínimo de',
      depoMax: 'Se puede depositar EURO',
      depoAdCopy: 'Dirección copiada',
      depoHistory: 'Historial de depósitos',
      minStake: "La apuesta mínima es",
      stkExcdBal: "Cantidad de apuesta que excede su saldo",
    },
    SUMMARY: {
      DAILYBONUS: 'Bonificación diaria',
      unableToGetSummary: 'Não foi possível obter o resumo do bônus, tente novamente mais tarde.',
      bnsTyp: 'Tipo de bônus',
      crntDat: 'Dia Atual',
      pndAmt: 'Valor pendente',
      ttlAmt: 'Valor total',
      dtlsHstry: 'Detalhes e história',
    },
    PROFITBONUS: {
      PROFITBONUS: 'Bonificación de rentabilidad',
      prd: 'Período',
      tltamt: 'Valor total',
      sts: 'Estado',
      dlt: 'Detalhes',
      unableToGetbonus: 'Incapaz de obter bônus, tente novamente mais tarde.'
    },
    PROFITEXBONUS: {
      PROFITEXBONUS: 'Bonificación extra de rentabilidad',
      unableToGetbonus: 'No se puede obtener la bonificación, inténtelo de nuevo más tarde.',
      bnsDt: 'Fecha de bonificación',
      frmusr: 'Desde el usuario',
      amtpyb: 'Importe a pagar',
    },
    AgentBonus: {
      AgentBonus: 'Bonificación de agente'

    },
    zoneAgentBonus: {
      zoneAgentBonus: 'Bonificación de agente de zona'

    },
    team: {
      tmNtw: 'Red de equipo',
      lvl: 'Nivel',
      usrnm: 'Nombre de usuario',
      flnm: 'Nombre completo',
      cntylc: 'País / Ubicación',
      typ: 'Tipo',
      iddt: 'I.D. Fecha',
      atcCp: 'Capital activo',
      unblerr: "No se puede obtener el equipo en este momento. Vuelve a intentarlo más tarde.",
    },
    news: {
      nws: 'Noticias'
    },
    internalMessages: {
      intnlMsg: 'Mensajes Internos',
      msg: 'Mensajes',
      ntfctn: 'Notificaciones'
    },
    supportTickets: {
      spprt: 'Soporte',
      crt: 'Crear',
      mytckt: 'Mis Tickets',
      unbleTopic: 'No se pueden cargar temas en este momento, inténtelo de nuevo más tarde.',
      unbleTicket: 'No se pueden cargar los datos del ticket en este momento, inténtelo de nuevo más tarde.',
      unbleTickets: 'No se pueden cargar boletos en este momento, intente nuevamente más tarde.',
      unbleCrtTickets: 'No se puede crear el ticket en este momento, intente nuevamente más tarde.',
      unbleSubRpl: 'No se puede responder al ticket en este momento, intente nuevamente más tarde.',
      msgRplReq: 'Se requiere un mensaje para responder',
      ifWish: 'Si quieres contactar con nuestro Soporte, puedes abrir un nuevo ticket.',
      ticketsTable: {
        sbjct: 'Asunto',
        tpc: 'Tópico',
        crtd: 'Creado',
        sts: 'Estado',
        actn: 'Acciones',
      },
      crtNwTckt: 'Crear un nuevo ticket',
      vwTckt: 'Ver ticket',
      msg: 'Mensaje',
      viw: 'Ver',
      rpl: 'Responder',
      cntRplyclsd: 'No se puede responder a este ticket'
    },
    mycapital: {
      yuHvErnd: 'Has ganado bonos de',
      yhbns: 'hoy. Consulta el resumen de tus',
      trns: 'transacciones',
      MycapAct: 'Mi actividad de capital',
      myCapWith: 'Mi actividad de retiros de capital',
      withCap: 'Capital de retiro',
      ttlCap: 'Capital total',
      myPendWithCap: 'Mi capital pendiente para retirar',
      myPendCap: 'Mi capital pendiente',
      myCapGain: 'Mis ganancias de capital',
      myNetCap: 'Mi capital neto',
      myActvCap: 'Mi capital activo',
      youErn: 'Te has ganado',
      bnsToday: 'bonificaciones hoy',
      chckTrns: 'Verificar transacciones en su',
      enterAmountinfo: 'Ingrese la cantidad en Euros que desea enviar desde el capital activo a su saldo disponible.',
      feeFor19: 'Se deducirá una tarifa de retiro del 19% sobre el monto.',
      enterWithAmount: 'Importe en Euros (€) *',
      exceedFrmBal: 'Cantidad que excede su saldo de capital activo',
      unableToGetWithList: 'No se puede obtener el historial de retiro de capital, inténtelo de nuevo más tarde.',
      unableToGetCapHistory: 'No se puede obtener el historial de capital, intente nuevamente más tarde.'
    },
    login: {
      wlcmTo: 'Bienvenido a',
      plzSgnAcc: 'Inicie sesión en su cuenta y comience la aventura',
      emlUsr: 'Correo electrónico / Nombre de usuario',
      pswd: 'Contraseña',
      rmbrMe: 'Recuérdame',
      frgtPwd: '¿Olvidó su contraseña?',
      sgnIn: 'Iniciar sesión',
      pwdReq: 'Se requiere contraseña',
      emlUsrReq: 'Se requiere correo electrónico / nombre de usuario',
      mustEmail: 'El correo electrónico debe ser una dirección de correo electrónico válida',
      captchaReq: 'Se requiere Captcha',
      usrReq: 'Se requiere nombre de usuario',
      usrnme: "Nombre de usuario",
      spnrReq: 'Se requiere patrocinador',
      spnr: "Patrocinador",
      emlReq: 'Se requiere correo electrónico',
      eml: "Correo electrónico",
      cnfrmpwd: "Confirmar contraseña",
      dscpZne: "Zonas discriminatorias",
      alrdyAcct: "¿Ya tienes una cuenta?",
      sngnInsd: "Iniciar sesión en su lugar",
      agrTo: "Acepto",
      trmCnd: "Términos y condiciones",
      rlePrcd: "Reglas y procedimientos",
      knwdTst: "Prueba de conocimientos",
      plzSngAdvtr: "Regístrese y comience la aventura",
      sngUp: "Registrarse",
      accVrfy: "Verifique su cuenta",
      affAccVrf: "Después de la verificación de la cuenta, puede comenzar a usar su cuenta",
      plzwait: "Espere mientras procesamos su solicitud",
      cngrts: "¡FELICIDADES!",
      emlvrfd: "SU CORREO ELECTRÓNICO ESTÁ VERIFICADO",
      thnkchc: "GRACIAS POR SU ELECCIÓN",
      yourRefUrl: "Su enlace de referencia",
      copiado: "Copiado al portapapeles",
      indvl: "Individual",
      compny: "Empresa",
      rgstrAs: "Registrarse como",
      rucompany: "Registrarse como empresa",
      compny_name: 'Nombre de la empresa',
      compny_nmbr: 'Número de contacto',
      compny_url: 'Url de la empresa',
      compny_adrss: 'Dirección de la empresa',
    },
    SVNG: {
      cnvHstry: 'Historial de conversiones',
      cnvtr: 'Congelar',
      unblGetHistory: 'No se puede obtener el historial de conversiones, inténtelo de nuevo más tarde.',
      minStake: "La conversión mínima es",
      stkExcdBal: "El monto de la conversión excede su saldo",
      svng: 'Ahorro',
      avlbErn: 'Ingresos disponibles',
      thsurAvlErn: 'Puede usar el saldo de ganancias disponible para convertirlo en saldo congelado para ahorrar',
      svngTerm: 'Aquí puede convertir sus "Ganancias disponibles" en "Saldo congelado". En Saldo congelado obtendrá un ROI del 0,25%.',
      rSrUlck: '¿Está seguro de que desea desbloquear?',
      rSrlck: '¿Está seguro de que desea congelar?',
      insvng: '¿en ahorros?',
      unckBlnc: 'Desbloquear saldo',
      frzeBlnc: 'Congelar saldo',
      frznAmt: 'Cantidad de Frozen',
      extrErng: 'Ingresos adicionales'
    },

    user: {
      usrList: 'Users List',
      fstName: 'First Name',
      lstName: 'Last Name',
      usrName: 'Username',
      cntry: 'País',
      usrEml: 'Email',
      sts: 'Status',
      drvrList: 'Drivers List'
    }

  }
};
