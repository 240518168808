//import { Component, OnInit } from '@angular/core';

//import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';


import { Component, OnInit } from '@angular/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { EncryptionService } from 'app/utils/encryption/encryption.service';
import { UserService } from 'app/auth/service';
import { AuthService } from 'app/auth/service/auth.service';
//import { AuthService } from 'app/auth/auth.service';


// // Interface
// interface notification {
//   messages: [];
//   systemMessages: [];
//   system: Boolean;
// }

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
 // public notifications: notification;


   // Public
   public notifications: any[] = [];

   /**
    *
    * @param {NotificationsService} _notificationsService
    */
   currentUser: User
   notifyLenght: number;
   showNotificaions: boolean;
  //constructor(private _notificationsService: NotificationsService) {}

  constructor(
    private _notificationsService: NotificationsService,
    private encryptionService: EncryptionService,
    private authService: AuthenticationService,
    private userService: UserService,
    private auth: AuthService,

  ){
    this.authService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUser = this.authService.currentUserValue;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
   ngOnInit(): void {
    // this._notificationsService.onApiDataChange.subscribe(res => {
    //   this.notifications = res;
    // });
    if (this.currentUser?._id)
      this.getNotifications();
  }

  getNotifications() {
    let enc = this.auth.encrypt(JSON.stringify({ userID: this.currentUser._id }))
    this.userService.getNotification(enc).subscribe(res => {
      console.log(res, 'dsa')
      if (!res.error) {
        this.notifications = res.body;
        const notiLenght = this.notifications.filter(nt => {
          return nt.is_checked == '0';
        })
        this.notifyLenght = notiLenght.length;
      } else {
        console.log(res.msg);
      }
    }, error => {
      console.log(error);
    });
  }

  toggleClass() {
    this.showNotificaions = !this.showNotificaions

    let nIds = [];
    this.notifications.forEach(el => {
      if (el.is_checked == '0') {
        nIds.push(el.id)
      }
    });
    const data = {
      notif_id: nIds,
      user_id: this.currentUser._id,
    }
    if (nIds.length)
      this.setAllReady(data);

  }

  setAllReady(data) {
    let encData = this.auth.encrypt(JSON.stringify(data))
    this.userService.setNotificationMarked({ enc: encData }).subscribe(res => {
      if (!res.error) {
        this.notifyLenght = 0;
      } else {
        console.log(res.msg);
      }
    }, error => {
      console.log(error);
    });

  }


}
