import { CoreMenu } from '@core/types';

export const menu: CoreMenu[] = [

  //  Menus
  //myaccount
  /*{
    id:'myaccount',
    title: 'My Account',
    translate: 'MENU.MYACC.COLLAPSIBLE',
    type: 'collapsible',
    icon: 'user',
    children: [
      {
        id: 'profile',
        title: 'Profile',
        translate: 'MENU.MYACC.PROFILE',
        type: 'item',
        icon: 'tool',
        url: 'my-account/profile'
      },
      {
        id: 'security',
        title: 'Security',
        translate: 'MENU.MYACC.SECURITY',
        type: 'item',
        icon: 'lock',
        url: 'my-account/security'
      },
      {
        id: 'emailContact',
        title: 'Email and Contact',
        translate: 'MENU.MYACC.EMAILCONTACT',
        type: 'item',
        icon: 'mail',
        url: 'my-account/email-contact'
      },
    ]
  }, */

  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'home',
    name: 'dashbaord',
    url: 'dashboard'
  },
  {
    id: 'users',
    title: 'Users',
    type: 'item',
    icon: 'users',
    name: 'users',
    url: 'users'
  },
  {
    id: 'kyc',
    title: 'KYC',
    type: 'item',
    fontAwesomeIcon: ' fa fa-file-text-o',
    icon: 'kyc',
    name: 'kyc',
    url: 'kyc'
  },

  // {
  //   id: 'menus',
  //   title: 'Menus',
  //   type: 'item',
  //   fontAwesomeIcon: ' fa fa-list-alt',
  //   role: ['Admin'],
  //   name: 'menus',
  //   url: 'menus'
  // },
  // {
  //   id: 'packs',
  //   title: 'Packs',
  //   type: 'item',
  //   fontAwesomeIcon: ' fa fa-percent',
  //   name: 'packs',
  //   url: 'packs'
  // },
  // {
  //   id: 'products',
  //   title: 'Products',
  //   type: 'item',
  //   fontAwesomeIcon: ' fa fa-columns',
  //   name: 'products',
  //   url: 'products'
  // },

  {
    id: 'tutorials',
    title: 'Tutorials',
    type: 'item',
    fontAwesomeIcon: ' fa fa-video-camera',
    name: 'tutorials',
    url: 'tutorials'
  },
  {
    id: 'Market Place',
    title: 'Market Place',
    type: 'item',
    fontAwesomeIcon: ' fa fa-home',
    name: 'Market Place',
    url: 'property'
  },
  {
    id: 'whitelist',
    title: 'Whitelist',
    type: 'item',
    icon: 'list',
    name: 'whitelist',
    url: 'whitelist'
  },
  {
    id: 'history',
    title: 'Transactions',
    type: 'item',
    fontAwesomeIcon: ' fa fa-history',
    name: 'history',
    url: 'history'
  },
  {
    id: 'setting',
    title: 'Settings',
    type: 'item',
    fontAwesomeIcon: ' fa fa-cog',
    name: 'setting',
    url: 'setting'
  },
  {
    id: 'notifications',
    title: 'Notifications',
    type: 'item',
    fontAwesomeIcon: ' fa fa-bell',
    name: 'notifications',
    url: 'notifications'
  },
  // {
  //   id: 'withdrawal',
  //   title: 'Withdrawal',
  //   type: 'item',
  //   fontAwesomeIcon: ' fa fa-money',
  //   name: 'withdrawal',
  //   url: 'withdrawal'
  // },
  // {
  //   id: 'unilevel',
  //   title: 'Unilevel',
  //   type: 'item',
  //   fontAwesomeIcon: ' fa fa-dollar',
  //   name: 'unilevel',
  //   url: 'unilevel'
  // },
  {
    id: 'downloads',
    title: 'Downloads',
    type: 'item',
    fontAwesomeIcon: ' fa fa-download',
    name: 'downloads',
    url: 'downloads'
  },
  {
    id: 'supportTickets',
    title: 'Support Tickets',
    type: 'item',
    fontAwesomeIcon: ' fa fa-ticket',
    name: 'support',
    url: 'support-tickets'
  },
  // {
  //   id: 'addBanner',
  //   title: 'Add Banner',
  //   type: 'item',
  //   fontAwesomeIcon: ' fa fa-ticket',
  //   name: 'addBanner',
  //   url: 'add-banner'
  // },
  // {
  //   id: 'addBanner',
  //   title: 'Add Banner',
  //   dupTitle:'Add Event',
  //   type: 'item',
  //   fontAwesomeIcon: ' fa fa-eye',
  //   name: 'addBanner',
  //   url: 'events'
  // },
  {
    id: 'faq',
    title: 'FAQ',
    type: 'item',
    fontAwesomeIcon: ' fa fa-question-circle',
    name: 'faq',
    url: 'faq'
  },
  // {
  //   id: 'transactions',
  //   title: 'Transactions',
  //   type: 'item',
  //   fontAwesomeIcon: ' fa fa-exchange',
  //   name: 'transactions',
  //   url: 'transactions'
  // },
  // {
  //   id: 'qualified-user',
  //   title: 'Qualified Users',
  //   type: 'item',
  //   fontAwesomeIcon: ' fa fa-user',
  //   name: 'qualified-user',
  //   url: 'qualified-user'
  // },
  // {
  //   id: 'email-templates',
  //   title: 'Email Templates',
  //   type: 'item',
  //   fontAwesomeIcon: ' fa fa-id-card-o',
  //   name: 'email-templates',
  //   url: 'email-templates'
  // },

];
