<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    &copy; {{ year }} SPLITLEVEL. All rights reserved
  </span>
  <span class="float-md-right d-none d-md-block">
    <span [data-feather]="'mail'" [class]="'pink'"></span> <a href="mailto:info@splitlevel.co" class="ml-1"> info@splitlevel.co</a>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
<!-- <div class="buy-now" *ngIf="currentUser && currentUser._id && currentUser.token">
  <a (click)="openDepositModal()" href="javascript:void(0)"  class="btn btn-danger">{{ 'DEPOSIT.depoNow' | translate}}</a>
</div> -->
