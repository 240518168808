<!-- <li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up">{{
      notifications.messages.length + notifications.systemMessages.length
    }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ notifications.messages.length + notifications.systemMessages.length }} New
        </div>
      </div>
    </li>
    
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <a class="d-flex" href="javascript:void(0)" *ngFor="let message of notifications.messages">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div class="avatar">
              <img [src]="message.image" alt="avatar" width="32" height="32" />
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="message.heading"></p>
            <small class="notification-text">{{ message.text }}</small>
          </div>
        </div></a
      >
    </li>
  </ul>
</li> -->




<li class="not-abs">
  <div class="btn-group dropdown">
    <a class="dropbtn" (click)="toggleClass()">
      <i class="ficon" data-feather="bell"></i>
      <span class="badge badge-pill badge-danger badge-up">{{notifyLenght}}</span>
    </a>
    <div class="dropdown-menu dropdown-content notification-card" [class.show]="showNotificaions">
      <i class="fa fa-caret-up drop-caret"></i>
      <ul>
        <li *ngFor="let noti of notifications">
          <div class="notification-body">
            <div class="media not-i-fication">
              <img class="mr-3" [src]="noti.img_url ? noti.img_url : '../../../../../assets/images/notification.png'">
              <div class="media-body">
                <p class="bito-noti-p">{{noti.title}}
                  <span>{{noti.crdate | date : 'dd/MM/yyyy'}} </span>
                </p>
                <p class="bito-noti-p1" [innerHTML]="noti.text"></p>
              </div>
            </div>
          </div>
        </li>
        <li class="text-center" *ngIf="!notifications.length">
          <i class="fa fa-bell-slash"></i> <br>
          <strong>No notifications</strong>
        </li>
      </ul>
    </div>
  </div>
</li>

