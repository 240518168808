import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { fakeBackendProvider } from 'app/auth/helpers'; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from 'app/auth/helpers';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { ContextMenuComponent } from 'app/main/extensions/context-menu/context-menu.component';
import { AnimatedCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component';
import { BasicCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component';
import { SubMenuCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component';
import { ToastrModule } from 'ng6-toastr-notifications';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
//import { MathCaptchComponent } from './main/math-captch/math-captch.component';


const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'dashboard',
    data: { title: 'Dashboard' },
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'components',
    loadChildren: () => import('./main/components/components.module').then(m => m.ComponentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    data: { title: 'Users' },
    loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'menus',
    data: { title: 'Menus' },
    loadChildren: () => import('./main/menus/menus.module').then(m => m.MenusModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'property',
    data: { title: 'Property' },
    loadChildren: () => import('./main/bithome/bithome.module').then(bh => bh.BithomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile/:id',
    data: { title: 'Profile' },
    loadChildren: () => import('./main/profile/my-account.module').then(m => m.MyAccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'support-tickets',
    data: { title: 'Support Tickets' },
    loadChildren: () => import('./main/support-ticket/support.module').then(m => m.SupportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tutorials',
    data: { title: 'Tutorials' },
    loadChildren: () => import('./main/tutorials/tutorials.module').then(m => m.TutorialsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'downloads',
    data: { title: 'Downloads' },
    loadChildren: () => import('./main/downloads/downloads.module').then(m => m.DownloadsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'popup',
    data: { title: 'Popup' },
    loadChildren: () => import('./main/popup/popup.module').then(m => m.PopupModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    data: { title: 'Notifications' },
    loadChildren: () => import('./main/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'transactions',
    data: { title: 'Transactions' },
    loadChildren: () => import('./main/transactions/transactions.module').then(m => m.TransactionModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'deposits',
  //   data: { title: 'Deposits' },
  //   loadChildren: () => import('./main/components/deposits/deposits.module').then(m => m.DepositsModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'setting',
    data: { title: 'Settings' },
    loadChildren: () => import('./main/setting/setting.module').then(st => st.SettingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'history',
    data: { title: 'Transactions' },
    loadChildren: () => import('./main/history/history.module').then(st => st.HistoryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'unilevel',
    data: { title: 'Unilevel' },
    loadChildren: () => import('./main/unilevel/unilevel.module').then(u => u.UnilevelModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'withdrawal',
    data: { title: 'Withdrawal' },
    loadChildren: () => import('./main/withdrawal/withdrawal.module').then(m => m.WithdrawalModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'faq',
    data: { title: 'FAQ' },
    loadChildren: () => import('./main/faq/faq.module').then(m => m.FaqModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'kyc',
    data: { title: 'KYC' },
    loadChildren: () => import('./main/kyc/kyc.module').then(k => k.KycModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'whitelist',
    data: { title: 'KYC' },
    loadChildren: () => import('./main/white-list/white-list.module').then(k => k.WhiteListModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-banner',
    data: { title: 'Add Banner' },
    loadChildren: () => import('./main/banner/banner.module').then(b => b.BannerModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'qualified-user',
    data: { title: 'Qualified Users' },
    loadChildren: () => import('./main/qualified-user/qualified-user.module').then(q => q.QualifiedUserModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'email-templates',
    data: { title: 'Email Templates' },
    loadChildren: () => import('./main/templates/templates.module').then(e => e.TemplatesModule),
    canActivate: [AuthGuard]

  },

  {
    path: 'events',
    data: { title: 'Events' },
    loadChildren: () => import('./main/new-events/new-events.module').then(e => e.NewEventsModule),
    canActivate: [AuthGuard]

  },


  {
    path: '**',
    loadChildren: () => import('./main/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule),
  }
];

@NgModule({
    declarations: [
        AppComponent,
        ContextMenuComponent,
        BasicCustomContextMenuComponent,
        AnimatedCustomContextMenuComponent,
        SubMenuCustomContextMenuComponent,
        //MathCaptchComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgImageFullscreenViewModule,
        HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: 'enabled'
}),
        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot(),
        ContextMenuModule,
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        CardSnippetModule,
        LayoutModule,
        ContentHeaderModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // provider used to create fake backend, comment while using real api
        fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
